<template>
<b-form class="form" @submit.stop.prevent="onSubmit">
  <b-container fluid class="p-0">
    <b-row>
      <b-col cols="12" md="8" offset-md="2">
        <b-form-group :label="`${$t('FORM.MAILER_FROM_EMAIL')}`" label-for="input-1" label-cols-md="3"
                      label-align-md="right"
        >
          <InputForm
            id="input-1"
            v-model="$v.form.mailerFromEmail.$model"
            :state="validateState('mailerFromEmail')"
            :placeholder="$t('FORM.MAILER_FROM_EMAIL')"
            trim
            @input="clearServerError('name')"
            aria-describedby="input-1-feedback"
          />
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.mailerFromEmail">{{ serverErrors.mailerFromEmail[0] }}</template>
            <template v-else>{{ $t('VALIDATION.EMAIL', {name: $t('FORM.MAILER_FROM_EMAIL')}) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('FORM.PROLONGATE_SERVICE_PRICES_DAYS_BEFORE')}`"
                      label-cols-md="3" label-align-md="right"
        >
          <InputForm
            id="input-2"
            type="number"
            v-model="$v.form.prolongateServicePricesDaysBefore.$model"
            :state="validateState('prolongateServicePricesDaysBefore')"
            :placeholder="$t('FORM.PROLONGATE_SERVICE_PRICES_DAYS_BEFORE')"
            trim
            @input="clearServerError('prolongateServicePricesDaysBefore')"
            aria-describedby="input-1-feedback"
          />
          <span class="form-text text-muted">{{ $t('FORM.PROLONGATE_SERVICE_PRICES_DAYS_BEFORE_HINT') }}</span>
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.prolongateServicePricesDaysBefore">
              {{ serverErrors.prolongateServicePricesDaysBefore[0] }}
            </template>
            <template v-else>{{
              $t('VALIDATION.NUMBER', {name: $t('FORM.PROLONGATE_SERVICE_PRICES_DAYS_BEFORE')})
            }}
            </template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('FORM.PROLONGATE_SERVICE_PRICES_DAYS_COUNT')}`" label-for="input-2"
                      label-cols-md="3" label-align-md="right"
        >
          <InputForm
            id="prolongateServicePricesDaysCount"
            type="number"
            v-model="$v.form.prolongateServicePricesDaysCount.$model"
            :state="validateState('prolongateServicePricesDaysCount')"
            :placeholder="$t('FORM.PROLONGATE_SERVICE_PRICES_DAYS_COUNT')"
            trim
            @input="clearServerError('prolongateServicePricesDaysCount')"
            aria-describedby="input-1-feedback"
          />
          <span class="form-text text-muted">{{ $t('FORM.PROLONGATE_SERVICE_PRICES_DAYS_COUNT_HINT') }}</span>
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.prolongateServicePricesDaysCount">
              {{ serverErrors.prolongateServicePricesDaysCount[0] }}
            </template>
            <template v-else>{{
              $t('VALIDATION.NUMBER', {name: $t('FORM.PROLONGATE_SERVICE_PRICES_DAYS_COUNT')})
            }}
            </template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('FORM.ACTIVE_TELEGRAM_BOT')}`" label-for="input-2" label-cols-md="3"
                      label-align-md="right"
        >
          <alioth-select
            v-model="$v.form.activeTelegramBot.$model"
            :placeholder="$t('FORM.ACTIVE_TELEGRAM_BOT')"
            :optionName="(opt) => opt.name"
            :optionValue="(opt) => opt.uuid"
            repository="bot/telegram"
            nullable
          />
          <b-form-invalid-feedback id="select-1-feedback" :class="{'d-block': validateState('activeTelegramBot') === false}">
            <template v-if="serverErrors.activeTelegramBot">{{ serverErrors.activeTelegramBot[0] }}</template>
            <template v-else>{{ $t('VALIDATION.NUMBER', { name: $t('FORM.ACTIVE_TELEGRAM_BOT') }) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="`${$t('FORM.CURRENCY_PROVIDERS')}`" label-for="input-2" label-cols-md="3"
                      label-align-md="right"
        >
          <alioth-select
            v-model="$v.form.currencyProviders.$model"
            :placeholder="$t('FORM.CURRENCY_PROVIDERS')"
            :optionName="(opt) => opt"
            :optionValue="(opt) => opt"
            :items="currencyProviders"
            multiple
          />
          <b-form-invalid-feedback id="select-1-feedback" :class="{'d-block': validateState('currencyProviders') === false}">
            <template v-if="serverErrors.currencyProviders">{{ serverErrors.currencyProviders[0] }}</template>
            <template v-else>{{ $t('VALIDATION.NUMBER', { name: $t('FORM.CURRENCY_PROVIDERS') }) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-col cols="12">
          <div class="text-right">
            <b-button :disabled="$loading || $v.form.$anyError" type="submit" variant="primary" size="sm">
              <b-spinner v-if="$loading" small variant="light" />
              {{ $t('FORM.SUBMIT') }}
            </b-button>
          </div>
        </b-col>
      </b-col>
    </b-row>
  </b-container>
</b-form>
</template>

<script>
import { email, integer, minLength } from 'vuelidate/lib/validators'
import Repo from '@/core/repository/company/settingsRepository'
import { validationMixin } from 'vuelidate'
import AliothSelect from '@/components/forms-items/alioth-select'
import serverVuelidate from '../../../mixins/serverVuelidate'
import InputForm from '../../forms-items/input'
import Entity from '../../../model/settings'

export default {
  name: 'BotForm',
  components: {
    AliothSelect,
    InputForm,
  },
  mixins: [validationMixin, serverVuelidate],
  props: {
    entity: {
      type: Entity,
      default: () => new Entity(),
    },
  },
  data() {
    return {
      form: new Entity(),
      currencyProviders: [
        'alfa',
        'monobank',
        'bank.gov.ua',
        'api.privatbank.ua',
      ],
      validations: {
        form: {
          mailerFromEmail: { email },
          prolongateServicePricesDaysBefore: { integer },
          prolongateServicePricesDaysCount: { integer },
          activeTelegramBot: {},
          currencyProviders: { minLength: minLength(1) },
        },
      },
    }
  },
  watch: {
    entity() {
      Object.assign(this.form, this.entity)
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.loadingOn()
      this.clearServerErrors()
      Repo.save(Object.assign(new Entity(), this.form))
        .then(this.$toastSuccess('TOAST.SUCCESS'))
        .catch((err) => {
          this.$toastDanger('TOAST.ERROR')
          this.seServerErrors(err.response.data)
        })
        .finally(this.loadingOff)
    },
  },
}
</script>

<style scoped>

</style>
